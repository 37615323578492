import gql from 'graphql-tag';

export default gql`
mutation setIsSharedTemplateOfProjects($projectId: Int!, $isSharedTemplate: Boolean!) {
  update_projects_by_pk(
    pk_columns: { project_id: $projectId },
    _set: { is_shared_template: $isSharedTemplate }) {
    project_id
  }
}
`;
