import gql from 'graphql-tag';

export default gql`
mutation c($id: Int!, $published: Boolean!) {
  update_pages_by_pk(
    pk_columns: { page_id: $id },
    _set: { published: $published }) {
    page_id
    updated_at
  }
}
`;
