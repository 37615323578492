import gql from 'graphql-tag';

export default gql`
mutation updatePagePublishStatus($projectId: Int!, $published: Boolean!) {
  update_projects_by_pk(
    pk_columns: { project_id: $projectId }, 
    _set: { published: $published }) {
    project_id
  }
}
`;
