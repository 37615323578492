import DeleteProjectTagsByProjectId from '@/graphQlQueries/mutations/deleteProjectTagsByProjectId';
import DuplicateSharedItem from '@/graphQlQueries/mutations/duplicateSharedItem';
import GetSharedTemplates from '@/graphQlQueries/queries/getSharedTemplates';
import SetIsSharedTemplateOfProjects from '@/graphQlQueries/mutations/setIsSharedTemplateOfProjects';
import SetLoadingIndicatorInCache from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetOrderOfProjects from '@/graphQlQueries/mutations/setOrderOfProjects';
import SetPublishStateOfPageById from '@/graphQlQueries/mutations/setPublishStateOfPageById';
import SetPublishStateOfProjectById from '@/graphQlQueries/mutations/setPublishStateOfProjectById';
import GetAllDescendantsOfItem from '@/graphQlQueries/queries/getAllDescendantsOfItem';
import GetGroupDataByGroupId from '@/graphQlQueries/queries/getGroupDataByGroupId';

const duplicateSharedTemplate = {
  methods: {
    async duplicateWhitePaperProject(newGroupId) {
      try {
        // run the loader
        await this.$apollo.mutate({
          mutation: SetLoadingIndicatorInCache,
          variables: {
            isIndicatorLoading: true,
          },
        });
        // get the data of the demo project
        const sharedTemplates = await this.$apollo.query({
          query: GetSharedTemplates,
          variables: {
            groupId: newGroupId,
          },
          fetchPolicy: 'network-only',
        });
        // get the element whitepaper from the array
        const demoProject = sharedTemplates.data.getSharedTemplates.find((project) => project.slug === 'whitepaper');
        if (!demoProject) {
          throw new Error('No whitepaper demo project found!');
        }
        // this is the data of the shared item
        const {
          slug, name, project_id,
        } = demoProject;
        const {
          data: {
            groups_by_pk: newGroupObject,
          },
        } = await this.$apollo.query({
          query: GetGroupDataByGroupId,
          variables: {
            groupId: newGroupId,
          },
          fetchPolicy: 'network-only',
        });
        const parent_slug_path = '/';
        const {
          root_folder_id,
          domain: group_domain,
          slug: group_slug,
        } = newGroupObject;
        const dataToBeSendToTheServer = {
          slug,
          parent_slug_path,
          group_slug,
          group_domain,
          type: 'project',
        };
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: 'duplicateItem',
          data: dataToBeSendToTheServer,
        });
        if (publishedDataWasSuccessfulSend) {
          const duplicateObject = {
            name,
            slug,
            destination_folder_id: root_folder_id,
            destination_project_id: null,
            page_id: null,
            project_id,
            group_id: newGroupId,
          };
          const { data: { duplicateSharedTemplate: duplicateItem } } = await this.$apollo.mutate({
            mutation: DuplicateSharedItem,
            variables: {
              object: duplicateObject,
            },
          });
          if (duplicateItem.project_id) {
            const { data } = await this.$apollo.query({
              query: GetAllDescendantsOfItem,
              variables: {
                itemPath: duplicateItem.project.item_path,
              },
            });
            if (data.page_descendants.length) {
              this.descendentPageIds = data.page_descendants.map((page) => page.page_id);
            }
            await this.unpublishDescendentProjectAndPages(duplicateItem.project_id);
            await this.setTheOrderOfTheDuplicatedItem(duplicateItem.project_id);
            await this.removeIsSharedTemplate(duplicateItem.project_id);
            await this.removeTagsFromProject(duplicateItem.project_id);
          } else {
            throw new Error('There was an error with duplication of demo project!');
          }
          await this.$apollo.mutate({
            mutation: SetLoadingIndicatorInCache,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }
      } catch (e) {
        console.error('Error', e);
        await this.$apollo.mutate({
          mutation: SetLoadingIndicatorInCache,
          variables: {
            isIndicatorLoading: false,
          },
        });
      }
    },
    async setTheOrderOfTheDuplicatedItem(projectId) {
      await this.$apollo.mutate({
        mutation: SetOrderOfProjects,
        variables: {
          projectId,
          orderIndex: 1,
        },
      });
      return 'order of duplicate item set';
    },
    async removeTagsFromProject(projectId) {
      await this.$apollo.mutate({
        mutation: DeleteProjectTagsByProjectId,
        variables: {
          projectId,
        },
      });
    },
    async unpublishDescendentProjectAndPages(projectId) {
      // unpublish project
      await this.$apollo.mutate({
        mutation: SetPublishStateOfProjectById,
        variables: {
          projectId,
          published: false,
        },
      });
      await Promise.all(this.descendentPageIds.map(async (pageId) => {
        await this.$apollo.mutate({
          mutation: SetPublishStateOfPageById,
          variables: {
            id: pageId,
            published: false,
          },
        });
      }));

      return 'updated publish status of project and page';
    },
    async removeIsSharedTemplate(projectId) {
      await this.$apollo.mutate({
        mutation: SetIsSharedTemplateOfProjects,
        variables: {
          projectId,
          isSharedTemplate: false,
        },
      });
      return 'set is_shared_template to false';
    },
  },
};

export default duplicateSharedTemplate;
