import gql from 'graphql-tag';

export default gql`
query getAllDescendantsOfItem($itemPath: String!) {
  folder_descendants(args: {ip: $itemPath}) {
    folder_id
    name
  }
  project_descendants(args: {ip: $itemPath}) {
    project_id
    name
  }
  page_descendants(args: {ip: $itemPath}) {
    page_id
    name
  }
}
`;
